<template>
  <div class="view-wrapper">
    <ViewHeader
      title="Team"
      :isFullViewport="true"
      :hideSectionHomeButton="true"
      :hideRoleTutorial="true"
    >
      <template #actions>
        <!-- <Button
          v-if="canUserManagePlanUsersChannelsSubscriptions"
          label="Add Team Member"
          icon="pi pi-plus-circle"
          @click="shouldShowInviteUser = true"
        /> -->
        <Button
        @click="()=>{
            $emit('complete')
            $router.back()
          }"
          label="Close"
          class="p-button-outlined p-ml-2"
        />
      </template>
    </ViewHeader>
    <ViewMain 
      :isFullViewport="true"
    >
      <div class="plan-settings-modal-content" style="max-width: 100rem; margin: 0 auto;">
        <div class="users-container">
          <p class="plan-settings-instructions">
            Add Team Members to your Plan and manage rights.<br/><br/>
            Click on the caret icon to the far right of the Team Member's name to change their role and edit rights. Scroll to the bottom of the list to add a Team Member. <br/>View <a class="text-link" target="_blank" href="https://www.annumplanning.com/resources/guides/adding-and-removing-team-members/">detailed instructions</a>.
          </p>
          <ul class="team-table">
            <div v-if="editingUser" class="team-table__edit-overlay">
              <div class="spinner">
                <ProgressSpinner strokeWidth="3" />
              </div>
            </div>
            <li class="team-table__head">
              <span id="firstName">First Name</span>
              <span id="lastName">Last Name</span>
              <span id="email">Email</span>
              <span id="role">Role
                <span
                  v-tooltip.top="roleTooltip"
                  class="pi pi-question-circle p-ml-2 tooltip-color"
                >
                </span>
              </span>
              <span id="budget" v-if="useBudgets && canUserViewBudgets">Budget
                <span
                  v-tooltip.top="budgetTooltip"
                  class="pi pi-question-circle p-ml-2 tooltip-color"
                >
                </span>
              </span>
              <span id="editRights">Channel Edit Rights
                <span
                  v-tooltip.top="channelEditRightsTooltip"
                  class="pi pi-question-circle p-ml-2 tooltip-color"
                ></span>
              </span>
            </li>

            <li
              class="team-table__item"
              v-for="(userRole, index) of filteredUserRoles"
              :key="userRole.id.intID"
            >
              <PlanSettingsUserDetail
                :key="index"
                :userRole="userRole"
                type="current"
              />
            </li>
          </ul>

          <ul class="team-table add-user-container p-mb-6">
            <li class="team-table__item add-user p-ai-end new-user-row" v-if="canUserEditPlanProperties">
              <div class="add-user__field" id="firstName">
                <label for="">First Name</label>
                <InputText
                  class="p-inputtext"
                  v-model="newUser.firstName"
                  @change="removeUserId"
                  autocomplete="new-password"
                />
              </div>
              <div class="add-user__field" id="lastName">
                <label for="">Last Name</label>
                <InputText
                  class="p-inputtext"
                  v-model="newUser.lastName"
                  @change="removeUserId"
                  autocomplete="new-password"
                />
              </div>
              <div class="add-user__field" id="email">
                <label for="">Email</label>
                <InputText
                  class="p-inputtext"
                  v-model="newUser.emailAddress"
                  @change="removeUserId"
                  autocapitalize="none"
                  autocorrect="off"
                  spellcheck="false"
                  autocomplete="new-password"
                />
              </div>
              <div class="add-user__field" id="role">
                <label for="">Role
                  <span
                    v-tooltip.top="roleTooltip"
                    class="pi pi-question-circle p-ml-2"
                  >
                  </span>
                </label>
                <Dropdown
                  v-model="newUser.role"
                  :options="roles"
                  optionLabel="title"
                  placeholder="Select"
                  @change="onNewUserRoleChange"
                />
              </div>
              <div
                class="add-user__field col-budget"
                id="budget"
                v-if="useBudgets && canUserViewBudgets"
              >
                <label for="">Budget
                  <span
                    v-tooltip.top="budgetTooltip"
                    class="pi pi-question-circle p-ml-2"
                  >
                  </span>
                </label>
                <Dropdown
                  v-model="newUser.canViewBudget"
                  :options="budgetOptions"
                  optionLabel="name"
                  optionValue="code"
                  :disabled="newUser.role.code.intID === 4"
                />
              </div>
              <div class="add-user__field col-edit-right" id="editRights">
                <label for=""
                  >Channel Edit Rights
                  <span
                    v-tooltip.top="channelEditRightsTooltip"
                    class="pi pi-question-circle p-ml-2"
                  ></span>
                </label>
                <MultiSelect
                  v-model="newUser.channels"
                  :options="channels"
                  dataKey="key"
                  optionLabel="name"
                  placeholder="Select"
                  :disabled="newUser.role.code.intID === 6 || newUser.role.code.intID === 4"
                  :class="{
                    'all-items-selected':
                      newUser.channels.length === channels.length,
                  }"
                >
                  <template #value="slotProps">
                    <template v-if="slotProps.value.length === channels.length">
                      All
                    </template>
                  </template>
                </MultiSelect>
              </div>
              <div class="edit-user-buttons">
                <div v-if="!shouldShowSelectAccountUserField">
                  <Button
                    class="p-button-text"
                    icon="pi pi-check"
                    @click="addUser"
                    :disabled="!allowAddUser || !isAddUserFormValid"
                  />
                  <Button
                    class="p-button-text"
                    icon="pi pi-times"
                    @click="cancelAddAccountUser"
                  />
                </div>
                <Button
                  v-else
                  label="Add Team Member"
                  class="p-button-text"
                  icon="pi pi-plus-circle"
                  @click="addUser"
                  :disabled="!allowAddUser || !isAddUserFormValid"
                />
              </div>
            </li>
            <li
              class="team-table__item p-d-flex p-flex-row p-ai-center"
              style="padding-top: 0;"
              v-if="shouldShowSelectAccountUserField && canUserEditPlanProperties"
            >
              <Dropdown
                v-model="selectAccountUserId"
                :options="visibleAccountUsers"
                :filter="true"
                dataKey="key"
                optionLabel="fullName"
                optionValue="id"
                placeholder="Select From Account"
                filterPlaceholder="Search By Name"
                @change="selectAccount"
              />
              <Button
                v-if="shouldShowAddSelectedAccountUserButton"
                label="Add User"
                class="p-button-text p-ml-4 addUserBtn"
                icon="pi pi-plus-circle"
                @click="addSelectedAccountUser"
              />
            </li>
          </ul>

          <p class="plan-settings-instructions" style="margin-left: 5rem; font-size: 1.4rem; text-align: left; max-width: 45rem;">
            Click “Select From Account” to view and add Team Members who have seats in the Account but do not yet have access to this Plan. Account Owners: To remove a Team Member from the Account and free up their seat, view our <a class="text-link" target="_blank" href="https://www.annumplanning.com/resources/guides/adding-and-removing-team-members/">detailed instructions</a>.
          </p>
        </div>
      </div>
    </ViewMain>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import ViewHeader from '@/components/ViewHeader.vue'
import ViewMain from '@/components/ViewMain.vue'
import Tooltip from 'primevue/tooltip'
import Plan, {PlanType} from '@/models/Plan'
import ID from '@/models/ID'
import AlertMessage, {
  AlertMessageSeverity,
} from '@/models/AlertMessage'
import Role from '@/models/Role'
import UserRole from '@/models/UserRole'
import User from '@/models/User'
import Channel from '@/models/Channel'
import PlanSettingsUserDetail from '@/components/PlanSettingsUserDetail.vue'
import ProgressSpinner from 'primevue/progressspinner'
import {fi} from 'date-fns/locale'

Vue.component('PlanSettingsUserDetail', PlanSettingsUserDetail)
Vue.component('ProgressSpinner', ProgressSpinner)

export default Vue.extend({
  name: 'PlanSettingsUsers',
  components: {
    ViewHeader,
    ViewMain,
  },
  directives: {
    tooltip: Tooltip,
  },
  data: () => {
    return {
      shouldShowSelectAccountUserField: true,
      shouldShowAddSelectedAccountUserButton: false,
      selectAccountUserId: {} as ID,
      shouldShowInviteUser: false,
      editingUser: false,
      allowAddUser: true,
      channelEditRightsTooltip: `Assign channel edit rights when you want to ensure Contributors only plan within their designated Channels. When you give a Contributor edit rights to a specific Channel, they can add and edit Tactics in that Channel but can only view Tactics in the other Channels.

        Account Owners and Plan Admins have edit rights for all Channels. Viewers have no Channel edit rights.
        `,
      roleTooltip: `Set each team member's role within the Plan. Every role contains the rights of the roles below it.

        Account Owner
        - Manages subscriptions, seats, and billing
        - One per account, contact Annum to transition to another team member

        Plan Admin
        - Sets up marketing Channels and Tactic Types
        - Enters Strategic Priorities
        - Manages Team Members
        - Activates Budget feature, sets annual Budget
        - Activates custom Calendars of Interest

        Contributor
        - Adds and edits Initiatives (Campaigns, Promotions, Key Dates, Monthly Themes)
        - Adds and edits Tactics

        Viewer
        - Views and filters data
        `,
      budgetTooltip: `Select whether you would like give Contributors and Viewers the ability to view Budget data in the Plan. Account Owners and Plan Admins can always view Budget data.`,
      newUser: {
        firstName: '',
        lastName: '',
        emailAddress: '',
        role: {
          title: '',
          code: new ID(),
        },
        canViewBudget: true,
        channels: [] as Channel[],
        userId: new ID(),
      },
      budgetOptions: [
        {
          name: 'Hide',
          code: false,
        },
        {
          name: 'View',
          code: true,
        },
      ],
    }
  },
  computed: {
    canUserEditPlanProperties(): boolean {
      return this.$store.getters.currentPlanPermissionLevel <= Role.LEVEL_PLAN_ADMIN
    },
    canUserManagePlanUsersChannelsSubscriptions(): boolean {
      return this.$store.getters.currentPlanPermissionLevel <= Role.LEVEL_PLAN_ADMIN
    },
    canUserViewBudgets(): boolean {
      return this.$store.getters.currentUserRole.canViewBudget
    },
    useBudgets(): boolean {
      return this.$store.getters.currentPlan.useBudgets
    },
    channels(): Channel[] {
      return this.$store.getters.currentPlan.channels.filter(
        (channel) => channel.name !== Channel.CHANNEL_NAME_INITIATIVES
      )
    },
    roles(): {}[] {
      return this.$store.getters.allRoles
        .filter((role) => role.level >= 250)
        .map((role) => ({
          title: role.title,
          code: role.id,
        }))
    },
    allAccountUsers(): User[] {
      return this.$store.getters.allAccountUsers
    },
    visibleAccountUsers(): User[] {
      return this.allAccountUsers.filter((user)=>{
        if(
          // Check that this user is not current user
          user.id.intID === this.$store.getters.currentUser.id.intID 
          // Check that this user is not already in plan
          || this.currentPlan.userRoles.filter((userRole)=>userRole.userId.intID === user.id.intID).length > 0 
          // Check that this user is unique in the account
          || this.$store.getters.currentAccountUserRoles.filter((userRole)=>userRole.userId.intID === user.id.intID).length === 0
          ){
          return false
        }
        return true
      })
    },
    selectedCurrentAccountUser(): User {
      const foundUser = this.allAccountUsers.find(
        (user) => user.id.intID === this.selectAccountUserId.intID
      )
      return foundUser ? foundUser : new User()
    },
    currentPlan(): Plan {
      return this.$store.getters.currentPlan
    },
    filteredUserRoles(): UserRole[] {
      return this.currentPlan.userRoles.filter((userRole)=>{
        // Filter out account owner role
        if(userRole.roleId.intID === 1){
          return false
        }

        // TODO: temporary fix for Patty appearing in users list on customer plans
        if(this.$store.getters.currentAccount.id.intID !== 1 && userRole.userId.intID === 3){
          return false
        }

        // Add item to list
        return true
      })
    },
    isLeadPlan(): boolean {
      return this.currentPlan.plans.length ? true : false
    },
    currentPlanChannels(): Channel[] {
      return this.currentPlan.channels.filter(
        (channel) => channel.name !== Channel.CHANNEL_NAME_INITIATIVES && ((this.isLeadPlan && !channel.isNested) || (!this.isLeadPlan && (channel.isLead || channel.isNested)) || (!channel.isLead && !channel.isNested))
      )
    },
    isAddUserFormValid(): boolean {
      return this.newUser.firstName !== '' && this.newUser.lastName !== '' && this.newUser.emailAddress !== '' && this.newUser.role.title !== ''
    }
  },
  methods: {
    removeUserId() {
      this.newUser.userId = new ID()
    },
    getRoleForId(roleId: ID) {
      return this.$store.getters.allRoles.find(
        (role) => role.id.intID == roleId.intID
      )
    },
    getAccountUserForId(userId: ID): User {
      const thisUser = this.$store.getters.allAccountUsers.find(
        (user) => user.id.intID == userId.intID
      )
      return thisUser ? thisUser : new User()
    },
    getAccountUserForEmail(email: string): User | null {
      const thisUser = this.$store.getters.allAccountUsers.find(
        (user) => user.emailAddress === email
      )
      return thisUser ? thisUser : null
    },
    handleRemoveUserFromPlan(userRole: UserRole, event) {
      this.$confirm.require({
        acceptLabel: 'YES',
        rejectLabel: 'NO',
        target: event.currentTarget,
        message: 'Are you sure?',
        accept: () => {
          this.$store.getters.services.users.deleteUserRole(userRole.id).then(
            () => {
              this.$store.dispatch('refreshCurrentPlan')
            },
            (error) => {
              Vue.prototype.$toast.add({
                severity: AlertMessageSeverity.error,
                summary: 'Error removing user from plan.',
                detail: error,
                life: 3000,
              })
            }
          )
        },
        reject: () => {
          // Remove user rejected
        },
      })
    },
    onNewUserRoleChange(event) {
      switch (event?.value?.code?.intID) {
        case 4:
        case 5:
          // Plan Admin (4) or Contributor (5) role selected
          if (!this.newUser.channels.length) {
            this.newUser.channels = this.channels
            this.newUser.canViewBudget = true
          }
          break
        case 6:
          // Viewer role selected
          this.newUser.channels = []
          this.newUser.canViewBudget = true
          break
        default:
          // Do nothing
          break
      }
    },
    selectAccount() {
      this.shouldShowAddSelectedAccountUserButton = true
    },
    addSelectedAccountUser() {
      this.shouldShowSelectAccountUserField = false
      this.shouldShowAddSelectedAccountUserButton = false
      this.newUser.firstName = this.selectedCurrentAccountUser.firstName
        ? this.selectedCurrentAccountUser.firstName
        : ''
      this.newUser.lastName = this.selectedCurrentAccountUser.lastName
        ? this.selectedCurrentAccountUser.lastName
        : ''
      this.newUser.emailAddress = this.selectedCurrentAccountUser.emailAddress
        ? this.selectedCurrentAccountUser.emailAddress
        : ''
      this.newUser.userId =
        this.selectedCurrentAccountUser.id.intID !== 0
          ? this.selectedCurrentAccountUser.id
          : new ID()
    },
    resetNewUser() {
      this.newUser = {
        firstName: '',
        lastName: '',
        emailAddress: '',
        role: {
          title: '',
          code: new ID(),
        },
        canViewBudget: true,
        channels: [],
        userId: new ID(),
      }
      this.selectAccountUserId = new ID()
    },
    addUser() {
      const accountUser = this.getAccountUserForEmail(this.newUser.emailAddress.trim())
      if(accountUser && this.currentPlan.userRoles.filter((userRole)=>userRole.userId.intID === accountUser?.id.intID).length > 0){
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.error,
          summary: 'A user with this email address already has access to the Plan.',
        })
        return
      }

      const isAddingAccountUser = this.newUser.userId.intID !== 0
      const role = this.$store.getters.allRoles.find(
        (el) => el.id.intID === this.newUser.role.code.intID
      )
      const newUserRole = new UserRole()
      newUserRole.canViewBudget = this.newUser.canViewBudget
      newUserRole.channels = this.newUser.channels.map(
        (channel) => channel.id
      )
      newUserRole.roleId = role.id
      newUserRole.planId = this.currentPlan.id
      newUserRole.accountId = this.$store.getters.currentAccount.id
      if(role.id === 4 || this.newUser.channels.length >= this.currentPlanChannels.length){
        newUserRole.canEditAllChannels = true
      }

      this.allowAddUser = false

      if (isAddingAccountUser) {
        newUserRole.userId = this.newUser.userId
        this.$store.getters.services.users
          .createUserRole(newUserRole)
          .then((newDbUserRole) => {
            this.currentPlan.userRoles.push(newDbUserRole)
            this.currentPlan.userRoleIds.push(newDbUserRole.id)
            this.$store.getters.currentAccount.userRoles.push(newDbUserRole)
            this.resetNewUser()
            this.shouldShowSelectAccountUserField = true
            this.allowAddUser = true
          },
          (error)=>{
            Vue.prototype.$toast.add({
              severity: AlertMessageSeverity.error,
              summary: `Error adding user to plan: ${error}`,
            })
            this.allowAddUser = true
          })
      } else {
        const newUser = User.fromResponseObject({
          firstName: this.newUser.firstName,
          lastName: this.newUser.lastName,
          emailAddress: this.newUser.emailAddress,
          status: 'pending',
        })
        this.$store.getters.services.users
          .create(newUser, this.$store.getters.currentAccount.id, this.$store.getters.currentPlan.id)
          .then((newDbUser: User) => {
            this.$store.getters.allAccountUsers.push(newDbUser)
            newUserRole.userId = newDbUser.id
            this.$store.getters.services.users
              .createUserRole(newUserRole)
              .then((newDbUserRole: UserRole) => {
                this.currentPlan.userRoles.push(newDbUserRole)
                this.currentPlan.userRoleIds.push(newDbUserRole.id)
                this.$store.getters.currentAccount.userRoles.push(newDbUserRole)
                this.resetNewUser()
                this.shouldShowSelectAccountUserField = true
                this.allowAddUser = true
              },
              (error)=>{
                Vue.prototype.$toast.add({
                  severity: AlertMessageSeverity.error,
                  summary: `Error adding new user to plan: ${error}`,
                })
                this.allowAddUser = true
              })
          },
          (error)=>{
            if(error.message.includes('status code 422') || error.message.includes('status code 409')){
              Vue.prototype.$toast.add({
                severity: AlertMessageSeverity.error,
                summary: 'This email address is connected to a user who already has a Seat in this Account. Give them access to this Plan by clicking on the Select From Account drop down.',
              })
            }else{
              Vue.prototype.$toast.add({
                severity: AlertMessageSeverity.error,
                summary: `Error creating user: ${error}`,
              })
            }
            this.allowAddUser = true
          })
      }
    },
    cancelAddAccountUser() {
      this.shouldShowSelectAccountUserField = true
      this.selectAccountUserId = new ID()
      this.resetNewUser()
    },
  },
})
</script>